import {
  Flex,
  Text,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  Divider,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import SEO from '../components/seo';
import NewBookSm from '../components/shop/new-book-sm';
import ProductPreview from '../components/shop/product-preview';
import slugify from '../utils/slugify';

const ShopPage = ({ data }) => {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  const { products } = data;
  const books = products.nodes.filter(
    (product) => product.productType === 'Book'
  );
  const workbooks = products.nodes.filter(
    (product) => product.productType === 'Workbook'
  );
  const onlineCourses = products.nodes.filter(
    (product) => product.productType === 'Course'
  );
  const resources = products.nodes.filter(
    (product) => product.productType === 'Resource'
  );
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={16}
        mb={32}
      >
        <Heading>Shop</Heading>
        <Text mt={3} color="gray.600" w={{ base: '100%', lg: '60%' }}>
          We've curated a selection of books and resources that compliment our
          course material. You can also purchase online courses and additional
          workbooks through the store.
        </Text>
        <Divider my={8} />
        <NewBookSm />
        <Tabs colorScheme="secondary" w="100%">
          <TabList mb={8} borderBottom="none">
            <TabList
              mb={10}
              display="grid"
              gridTemplateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(5, 1fr)',
              }}
              gridTemplateRows={{ base: 'repeat(5, 1fr)', md: '1fr' }}
              w="100%"
              gridGap={{ base: 4, md: 16 }}
              justifyContent="center"
              alignItems="center"
            >
              <Tab>All products</Tab>
              <Tab>Books</Tab>
              <Tab>Resources</Tab>
              <Tab>Workbooks</Tab>
              <Tab>Online courses</Tab>
            </TabList>
          </TabList>
          <TabPanels w="100%">
            <TabPanel
              display="flex"
              flexDir="column"
              w="100%"
              p={0}
              mb={{ base: 16, md: 32 }}
            >
              <Grid
                gridTemplateColumns={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(5, 1fr)',
                }}
                gap={{ base: 8, md: 12, lg: 20 }}
                w="100%"
              >
                {products.nodes.map((product, index) => {
                  const slug = slugify(product.title);
                  return (
                    <ProductPreview
                      key={index}
                      name={product.title}
                      type={product.productType}
                      image={
                        product.media[0]?.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      brand={product.vendor}
                      price={product.priceRangeV2.maxVariantPrice.amount}
                      path={slug}
                    />
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel w="100%" p={0}>
              <Grid
                gridTemplateColumns={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(5, 1fr)',
                }}
                gap={{ base: 8, md: 12, lg: 20 }}
                w="100%"
              >
                {books.map((book, index) => {
                  const slug = slugify(book.title);
                  return (
                    <ProductPreview
                      key={index}
                      name={book.title}
                      type={book.productType}
                      image={
                        book.media[0]?.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      brand={book.vendor}
                      price={book.priceRangeV2.maxVariantPrice.amount}
                      path={slug}
                    />
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel w="100%" p={0}>
              <Grid
                gridTemplateColumns={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(5, 1fr)',
                }}
                gap={{ base: 8, md: 12, lg: 20 }}
                w="100%"
              >
                {resources.map((resource, index) => {
                  const slug = slugify(resource.title);
                  return (
                    <ProductPreview
                      key={index}
                      name={resource.title}
                      type={resource.productType}
                      image={
                        resource.media[0]?.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      brand={resource.vendor}
                      price={resource.priceRangeV2.maxVariantPrice.amount}
                      path={slug}
                    />
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel w="100%" p={0}>
              <Grid
                gridTemplateColumns={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(5, 1fr)',
                }}
                gap={{ base: 8, md: 12, lg: 20 }}
                w="100%"
              >
                {workbooks.map((workbook, index) => {
                  const slug = slugify(workbook.title);
                  return (
                    <ProductPreview
                      key={index}
                      name={workbook.title}
                      type={workbook.productType}
                      image={
                        workbook.media[0]?.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      brand={workbook.vendor}
                      price={workbook.priceRangeV2.maxVariantPrice.amount}
                      path={slug}
                    />
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel w="100%" p={0}>
              <Grid
                gridTemplateColumns={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(5, 1fr)',
                }}
                gap={{ base: 8, md: 12, lg: 20 }}
                w="100%"
              >
                {onlineCourses.map((onlineCourse, index) => {
                  const slug = slugify(onlineCourse.title);
                  return (
                    <ProductPreview
                      key={index}
                      name={onlineCourse.title}
                      type={onlineCourse.productType}
                      image={
                        onlineCourse.media[0]?.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      brand={onlineCourse.vendor}
                      price={onlineCourse.priceRangeV2.maxVariantPrice.amount}
                      path={slug}
                    />
                  );
                })}
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
};

export default ShopPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(filter: { pageName: { eq: "shop" } }) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    bookCovers: allFile(filter: { sourceInstanceName: { eq: "books" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 400)
          }
        }
      }
    }

    products: allShopifyProduct {
      nodes {
        description
        vendor
        id
        title
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
        }
        productType
        media {
          ... on ShopifyMediaImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
        }
      }
    }
  }
`;
